
<template>
  <div class="box">
    <div class="route">
      <img class="logo" src="@/assets/footer-logo.jpg" />
      <div class="menu">
        <span v-for="(i, index) in menu" :key="index" @click="changeIndex(i)">{{
          i.text
        }}</span>
      </div>
      <div class="hengxian"></div>
      <div class="lianxifangshi">
        <!-- <div class="erweima">
          <div class="tupian"></div>
          <div class="wenzi">扫一扫 微信公众号</div>
        </div> -->
        <div class="jiameng">
          <div class="item">全国加盟热线：400-6652-666</div>
          <div class="item">
            地址：北京市丰台区南四环西路188号总部基地7区8号楼
          </div>
        </div>
      </div>
    </div>
    <div class="beian">
      <div class="haoma" @click="gotoBeian">京ICP备2022000927号</div>
    </div>
  </div>
</template>

<script>
import menu from '@/utils/menu'
export default {
  name: 'footerHead',
  data() {
    return {
      menu: menu,
    }
  },
  methods: {
    changeIndex(e) {
      this.$router.push(e.path + '')
    },
    gotoBeian(){
      window.open("https://beian.miit.gov.cn/#/Integrated/index", "_blank");
    }
  },
}
</script>

<style lang="scss" scoped>
.box {
  width: 100%;
  // height: 610px;
  // background: #e4e4e4;
  .route {
    background: #f7f7f7;
    padding-bottom: 50px;
    .logo {
      margin: 50px auto;
    }
    .menu {
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #000000;
      max-width: 760px;
      display: flex;
      justify-content: space-between;
      margin: 0 auto 50px;
      cursor: pointer;
    }
    .hengxian {
      max-width: 1476px;
      // padding: 0 11%;
      height: 2px;
      background: #e4e4e4;
      margin: 0 auto 50px;
    }
    .lianxifangshi {
      display: flex;
      margin: 0 auto;
      max-width: 580px;
      .erweima {
        display: flex;
        flex-direction: column;
        .tupian {
          width: 123px;
          height: 123px;
          background: #e4e4e4;
        }
        .wenzi {
          font-size: 15px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #808791;
          margin-top: 18px;
        }
      }
      .jiameng {
        display: flex;
        flex-direction: column;
        text-align: left;
        margin: auto 0 auto 40px;
        .item {
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #000000;
          margin: 15px 0;
        }
      }
    }
  }
  .beian {
    width: 100%;
    height: 103px;
    display: flex;
    background: #e4e4e4;
    .haoma {
      margin: auto;
    }
    .haoma:hover{
      cursor: pointer;
    }
  }
}
@media only screen and (max-width: 771px) {
  .box .route .menu {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #000000;
    max-width: 760px;
    display: flex;
    justify-content: space-between;
    margin: 0 auto 50px;
    cursor: pointer;
    padding: 0 10px;
  }
  .box .route .lianxifangshi .erweima .wenzi {
      font-size: 12px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #808791;
      margin-top: 18px;
    }
  .box .route .lianxifangshi .jiameng .item {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #000000;
    margin: 15px 0;
  }
}
</style>