
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    // component: HomeView
    component: () => import(/* webpackChunkName: "about" */ '../views/account/home.vue')
  },
  {
    path: '/about',
    name: 'about',
    // component: HomeView
    component: () => import(/* webpackChunkName: "about" */ '../views/account/about.vue')
  },
  {
    path: '/recharge',
    name: 'recharge',
    // component: HomeView
    component: () => import(/* webpackChunkName: "about" */ '../views/account/recharge/advantage.vue')
  },
  {
    path: '/recharge/apply',
    name: 'recharge-apply',
    // component: HomeView
    component: () => import(/* webpackChunkName: "about" */ '../views/account/recharge/apply.vue')
  },
  {
    path: '/recharge/policy',
    name: 'recharge-policy',
    // component: HomeView
    component: () => import(/* webpackChunkName: "about" */ '../views/account/recharge/policy.vue')
  },
  {
    path: '/recharge/cooperate',
    name: 'recharge-cooperate',
    // component: HomeView
    component: () => import(/* webpackChunkName: "about" */ '../views/account/recharge/cooperate.vue')
  },
  {
    path: '/information',
    name: 'information',
    // component: HomeView
    component: () => import(/* webpackChunkName: "about" */ '../views/account/information.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    // component: HomeView
    component: () => import(/* webpackChunkName: "about" */ '../views/account/contact.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
