
let menu = [
    {
        path: '/',
        text: '首页',
        index: 0
    },
    {
        path: '/about',
        text: '哇牛科技',
        index: 1,
    },
    {
        path: '/recharge',
        text: '加盟哇牛',
        children: [
            { path: '/recharge/policy', text: '加盟政策' },
            { path: '/recharge', text: '核心优势' },
            { path: '/recharge/cooperate', text: '合作案例' },
            { path: '/recharge/apply', text: '加盟申请' },
        ],
        index: 2
    },
    {
        path: '/information',
        text: '资讯中心',
        index: 3
    },
    {
        path: '/contact',
        text: '联系我们',
        index: 4
    },
]
export default menu
