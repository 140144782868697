

<template>
  <div class="box">
    <div class="head">
      <img src="@/assets/logo.jpg" />
      <div class="phone-info">
        <span class="iconfont icon-lianxidianhua"></span>
        <span class="jiamen">加盟热线: </span>
        <span class="phone">400-6652-666</span>
      </div>
    </div>
    <div class="menu-box" @mouseleave="moveout">
      <div class="menu">
        <div
          class="menu-item"
          v-for="(item, index) in menu"
          :key="index"
          :class="[Path == item.path || pathShow ? 'active' : '']"
          @click="changeIndex(item)"
          @mouseenter="move(item, index)"
        >
          <span>{{ item.text }}</span>
        </div>
      </div>
      <!-- v-show="show" -->
      <!--  :style="{ 'padding-left': paddingLeft + 'px' }" -->
      <div
        v-show="show"
        class="menu-item-children"
        @mouseleave="moveout"
        :style="{ 'padding-left': 20 + '%' }"
      >
        <div>
          <span
            v-for="(item, index) in childrenList"
            :key="index"
            class="children-item"
            :class="[childrenPath == item.path ? 'childrenItem-active' : '']"
            @click="changeItem(item, index)"
            @mouseenter="moveItem(item, index)"
            >{{ item.text }}</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import menu from '@/utils/menu'
export default {
  data() {
    return {
      menu: menu,
      // Path: '/',
      pathShow: false,
      // childrenPath: -1,
      show: false,
      childrenList: [],
      paddingLeft: 0,
    }
  },
  computed: {
    // show() {
    //   return this.childrenList.length > 0 ? true : false
    // },
    Path() {
      // return this.$route.path
      let path = this.$route.path.split('/')[1]
      console.log(path)
      return '/' + path
    },
    childrenPath() {
      return this.$route.path
    },
  },
  mounted() {
    // this.Path = this.$route.path
  },
  methods: {
    changeIndex(e) {
      // this.Path = e.path
      // if (e.children) {
      //   this.childrenPath = e.children[1].path
      // } else {
      //   this.childrenPath = -1
      // }
      this.$router.push(e.path + '')
      this.moveout()
    },
    changeItem(item, index) {
      this.show = false
      // this.childrenPath = item.path
      this.$router.push(item.path + '')
    },
    move(e, index) {
      if (e.children) {
        this.childrenList = e.children
        this.show = true
        this.paddingLeft = index == 0 ? 0 : (e.children.length + 1) * 60
      } else {
        this.childrenList = []
        this.show = false
      }
    },
    moveout() {
      this.childrenList = []
      this.show = false
    },
    moveItem(e, index) {},
  },
}
</script>

<style lang="scss" scoped>
.head {
  // background: #00000010;
  padding: 0 7%;
  height: 122px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-content: center;
  text-align: center;
  flex-wrap: wrap;
  justify-content: space-between;
  img {
    width: 20%;
    border: 0;
    // height: 67px;
  }

  .phone-info {
    text-align: center;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    .iconfont {
      font-size: clamp(16px, 0.489rem + 1.05vw, 18px);
      // padding: 2px 3px;
      // width: 30px;
      // height: 30px;
      // line-height: 30px;
      // box-sizing: border-box;
      // border-radius: 50%;
      // border: 1px solid #000;
      margin: 0 15px 0 0;
      transform: translateY(20%);
    }
    .jiamen {
      font-size: clamp(18px, 0.489rem + 1.05vw, 36px);
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #000000;
    }
    .phone {
      font-size: clamp(18px, 0.489rem + 1.05vw, 36px);
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #0081cc;
      margin-left: 10px;
    }
  }
}
.menu-box {
  background: #0081cc;
  width: 100%;
  height: 87px;
  position: relative;
  .menu {
    height: 73px;
    background: #fff;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    box-sizing: border-box;
    padding-left: 15%;
    .menu-item {
      font-size: clamp(12px, 1.05vw, 24px);
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #000;
      padding: 0 2%;
      cursor: pointer;
      margin-left: 1%;
    }

    // .menu-item:hover {
    //   height: 100%;
    //   background: #0081cc;
    //   line-height: 73px;
    //   color: #fff;
    // }

    .active {
      // width: 179px;
      height: 100%;
      background: #0081cc;
      line-height: 73px;
      color: #fff;
      // margin-right: 131px;
    }
  }
  .menu-item-children {
    position: absolute;
    width: 100%;
    height: 88px;
    background: #ffffffc8;
    // background: #00000010;
    left: 0;
    top: 87px;
    line-height: 88px;
    display: flex;
    box-sizing: border-box;
    align-content: center;
    text-align: center;
    // display: none;
    // opacity: 0.8;
  }
}
.children-item {
  // font-size: 24px;
  font-size: clamp(12px, 1.05vw, 24px);
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #2d2f3a;
  margin: 0 65px 0 0;

  cursor: pointer;
}
.children-item:hover {
  color: #005bac;
}
.childrenItem-active {
  color: #005bac;
}
@media only screen and (max-width: 771px) {
  .menu-box .menu {
    height: 73px;
    background: #fff;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    box-sizing: border-box;
    padding-left: 8%;
  }
}
</style>




